<script setup>
const close = () => {
  window.close()
}
</script>

<template>
  <Button 
    v-if="$router.options.history.state.position > 0"
    label="Back"
    class="p-button-text p-button-plain"
    @click="$router.back()"
  />
  <Button
    v-else
    label="Close"
    class="p-button-text p-button-plain"
    @click="close"
  />
</template>
