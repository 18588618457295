<script setup>
  import { computed, defineEmits, defineProps, ref, watch } from 'vue'
  import { useVuelidate } from "@vuelidate/core"

	import { toDateTime } from '@/scripts/datetime'
  import form from '@/scripts/form'

  const changed = ref(false)
  const loading = ref(false)

  const emit = defineEmits(['update:modelValue'])
  const props = defineProps(['id', 'modelValue'])

  const modelValue = computed(() => props.modelValue)

  const rules = {
    from_hfm_id: {},
    type: {},
    last_trade: {},
    volume: {},
    balance: {},
    commission: {},
    account_currency: {},
    country: {},
    rebates_paid: {},
    rebates_unpaid: {},
    rebates_rejected: {},
    first_trade: {},
    first_funding: {},
    registration: {},
    server: {},
    platform: {},
    conversion_device: {},
    deposits: {},
    withdrawals: {},
    name: {},
    email: {},
    equity: {},
    margin: {},
    free_margin: {},
    campaign: {},
    leverage: {},
    bonus_type: {},
    trades: {},
    bonus: {},
  }

  const model = form.initial(rules, modelValue.value)

  const v$ = useVuelidate(rules, model)

  watch(model, (newValue) => {
    changed.value = true

    emit('update:modelValue', newValue)
  })

  loading.value = false
</script>

<template>
  <label class="block text-900 font-medium mb-2" for="from_hfm_id">From HFM ID</label>
  <InputText v-model="v$.from_hfm_id.$model" id="from_hfm_id" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="type">Type</label>
  <InputText v-model="v$.type.$model" id="type" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="last_trade">Last Trade</label>
  <InputText :value="v$.last_trade.$model ? (new Date(v$.last_trade.$model)).toLocaleString() : ''" id="last_trade" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="volume">Volume</label>
  <InputText v-model="v$.volume.$model" id="volume" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="balance">Balance</label>
  <InputText v-model="v$.balance.$model" id="balance" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="commission">Commission</label>
  <InputText v-model="v$.commission.$model" id="commission" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="account_currency">Account Currency</label>
  <InputText v-model="v$.account_currency.$model" id="account_currency" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="country">Country</label>
  <InputText v-model="v$.country.$model" id="country" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="rebates_paid">Rebates Paid</label>
  <InputText v-model="v$.rebates_paid.$model" id="rebates_paid" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="rebates_unpaid">Rebates Unpaid</label>
  <InputText v-model="v$.rebates_unpaid.$model" id="rebates_unpaid" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="rebates_rejected">Rebates Rejected</label>
  <InputText v-model="v$.rebates_rejected.$model" id="rebates_rejected" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="first_trade">First Trade</label>
  <InputText :value="v$.first_trade.$model ? (new Date(v$.first_trade.$model)).toLocaleString() : ''" id="first_trade" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="first_funding">First Funding</label>
  <InputText :value="v$.first_funding.$model ? (new Date(v$.first_funding.$model)).toLocaleString() : ''" id="first_funding" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="registration">Registration</label>
  <InputText :value="(new Date(v$.registration.$model)).toLocaleString()" id="registration" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="server">Server</label>
  <InputText v-model="v$.server.$model" id="server" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="platform">Platform</label>
  <InputText v-model="v$.platform.$model" id="platform" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="conversion_device">Conversion Device</label>
  <InputText v-model="v$.conversion_device.$model" id="conversion_device" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="deposits">Deposits</label>
  <InputText v-model="v$.deposits.$model" id="deposits" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="withdrawals">Withdrawals</label>
  <InputText v-model="v$.withdrawals.$model" id="withdrawals" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="name">Name</label>
  <InputText v-model="v$.name.$model" id="name" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="email">Email</label>
  <InputText v-model="v$.email.$model" id="email" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="equity">Equity</label>
  <InputText v-model="v$.equity.$model" id="equity" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="margin">Margin</label>
  <InputText v-model="v$.margin.$model" id="margin" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="free_margin">Free Margin</label>
  <InputText v-model="v$.free_margin.$model" id="free_margin" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="campaign">Campaign</label>
  <InputText v-model="v$.campaign.$model" id="campaign" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="leverage">Leverage</label>
  <InputText v-model="v$.leverage.$model" id="leverage" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="bonus_type">Bonus Type</label>
  <InputText v-model="v$.bonus_type.$model" id="bonus_type" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="trades">Trades</label>
  <InputText v-model="v$.trades.$model" id="trades" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="bonus">Bonus</label>
  <InputText v-model="v$.bonus.$model" id="bonus" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="created_at">Created At</label>
  <InputText :value="toDateTime(modelValue.created_at)" id="created_at" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="updated_at">Updated At</label>
  <InputText :value="toDateTime(modelValue.updated_at)" id="updated_at" type="text" class="w-full mb-3" readonly />
</template>
